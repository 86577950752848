import { useState } from 'react';
import './App.css';
import { Link } from 'react-scroll';

function Header() {
  const [showNavbar, setShowNavbar]  = useState(false);
  const togglenavBar = ()=> {
    setShowNavbar(!showNavbar);
  }
  return (
<nav class="bg-white border-gray-200 dark:bg-gray-900">
  <div class="max-w-screen-xl flex flex-wrap items-center justify-between mx-auto p-4">
    <a href="https://flowbite.com/" class="flex items-center space-x-3 rtl:space-x-reverse">
    <img 
    src={`${process.env.PUBLIC_URL}/logo.png`} 
    alt="Travel Agency Logo" 
    style={{ width: 'auto', height: '60px' }} 
  />
    </a>
    <button data-collapse-toggle="navbar-default" type="button" class="inline-flex items-center p-2 w-10 h-10 justify-center text-sm text-gray-500 rounded-lg md:hidden hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-gray-200 dark:text-gray-400 dark:hover:bg-gray-700 dark:focus:ring-gray-600" aria-controls="navbar-default" aria-expanded="false" onClick={togglenavBar}>
        <span class="sr-only">Open main menu</span>
        <svg class="w-5 h-5" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 17 14">
            <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M1 1h15M1 7h15M1 13h15"/>
        </svg>
    </button>
    <div class="w-full md:block md:w-auto" className={`${showNavbar ? "visible w-full md:block md:w-auto" : "hidden"} md:visible md:block md:w-auto`} id="navbar-default">
      <ul class="font-medium flex flex-col p-4 md:p-0 mt-4 text-white border border-gray-100 rounded-lg bg-gray-50 md:flex-row md:space-x-8 rtl:space-x-reverse md:mt-0 md:border-0 md:bg-white dark:bg-gray-800 md:dark:bg-gray-900 dark:border-gray-700">
        <li>
          <a href="#" class="block text-2xl font-semibold hover:scale-125 transition-transform py-2 px-3 text-white bg-blue-700 rounded md:bg-transparent md:text-blue-700 md:p-0 dark:text-white md:dark:text-blue-500" aria-current="page">Home</a>
        </li>
        <li>
          <Link to="ticket-booking" smooth={true}  duration={500} className='block text-2xl font-semibold hover:scale-125 transition-transform py-2 px-3 text-gray-900 rounded hover:bg-gray-100 md:hover:bg-transparent md:border-0 md:hover:text-blue-700 md:p-0 dark:text-white md:dark:hover:text-blue-500 dark:hover:bg-gray-700 dark:hover:text-white md:dark:hover:bg-transparent'>Ticket Booking</Link>
        </li>
        <li>
        <Link to="visa-booking" smooth={true}  duration={500} className='block text-2xl font-semibold hover:scale-125 transition-transform py-2 px-3 text-gray-900 rounded hover:bg-gray-100 md:hover:bg-transparent md:border-0 md:hover:text-blue-700 md:p-0 dark:text-white md:dark:hover:text-blue-500 dark:hover:bg-gray-700 dark:hover:text-white md:dark:hover:bg-transparent'>Visa Facilitation </Link>
        </li>
        <li>
        <Link to="hotel-booking" smooth={true}  duration={500} className='block text-2xl font-semibold hover:scale-125 transition-transform py-2 px-3 text-gray-900 rounded hover:bg-gray-100 md:hover:bg-transparent md:border-0 md:hover:text-blue-700 md:p-0 dark:text-white md:dark:hover:text-blue-500 dark:hover:bg-gray-700 dark:hover:text-white md:dark:hover:bg-transparent'>Hotel Booking </Link>
        </li>
        <li>
        <Link to="travel-insurance" smooth={true}  duration={500} className='block text-2xl font-semibold hover:scale-125 transition-transform py-2 px-3 text-gray-900 rounded hover:bg-gray-100 md:hover:bg-transparent md:border-0 md:hover:text-blue-700 md:p-0 dark:text-white md:dark:hover:text-blue-500 dark:hover:bg-gray-700 dark:hover:text-white md:dark:hover:bg-transparent'>Travel Insurance</Link>
        </li>
      </ul>
    </div>
  </div>
</nav>
  );
}

export default Header;